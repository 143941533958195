import { render, staticRenderFns } from "./sidebar-checkout-booking.vue?vue&type=template&id=0c4aea7f&"
import script from "./sidebar-checkout-booking.vue?vue&type=script&lang=js&"
export * from "./sidebar-checkout-booking.vue?vue&type=script&lang=js&"
import style0 from "./sidebar-checkout-booking.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c4aea7f')) {
      api.createRecord('0c4aea7f', component.options)
    } else {
      api.reload('0c4aea7f', component.options)
    }
    module.hot.accept("./sidebar-checkout-booking.vue?vue&type=template&id=0c4aea7f&", function () {
      api.rerender('0c4aea7f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/blocks/sidebar/sidebar-checkout-booking.vue"
export default component.exports