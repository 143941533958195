var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebarCheckoutPublic",
      attrs: { id: "sidebar", "data-e2e": "sidebarCheckoutPublic" },
    },
    [
      _c(
        "div",
        {
          staticClass: "checkoutBack",
          on: {
            click: function ($event) {
              return _vm.mixinGoBack()
            },
          },
        },
        [_c("BtnBack", { attrs: { text: "Torna indietro" } })],
        1
      ),
      _c("div", { staticClass: "sidebar-inner" }, [
        _c(
          "div",
          {
            class:
              _vm.mixinGetIntegrationInfo().mode == "boxset"
                ? "hasBackground"
                : "",
            attrs: { id: "sidebarCheckout" },
          },
          [
            _c(
              "div",
              { staticClass: "sidebarStep bgGray" },
              [
                _vm._m(0),
                _c("hr"),
                _vm.mixinGetIntegrationInfo().mode == "boxset"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n                              Salabam " +
                                _vm._s(
                                  _vm._f("capitalize")(_vm.bookingData.focus)
                                ) +
                                " " +
                                _vm._s(
                                  _vm._f("capitalize")(_vm.bookingData.band)
                                ) +
                                " "
                            ),
                            _c("span", [
                              _vm._v(_vm._s(_vm.dates.nights) + " "),
                              _vm.dates.nights > 1
                                ? _c("span", [_vm._v("notti")])
                                : _c("span", [_vm._v("notte")]),
                            ]),
                          ]),
                          _vm.$config.guiSettings.giftCardsEnabled
                            ? _c(
                                "div",
                                { staticClass: "qty" },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      size: "sm",
                                      type: "number",
                                      placeholder: _vm.qty.toString(),
                                      min: 1,
                                      max: _vm.$config.guiSettings
                                        .boxsetOrderMaxQty,
                                    },
                                    model: {
                                      value: _vm.qty,
                                      callback: function ($$v) {
                                        _vm.qty = $$v
                                      },
                                      expression: "qty",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.bookingData.public_price) + " €"
                                ),
                              ]),
                        ]
                      ),
                      _c("hr", { staticClass: "mt-2 mb-2" }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _c("strong", [_vm._v("Totale")]),
                          _c("strong", [_vm._v(_vm._s(_vm.price) + " €")]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.mixinGetIntegrationInfo().mode == "booking"
                  ? _c("div", [
                      _c(
                        "div",
                        [
                          _c("SvgBedBlack", { staticClass: "mr-2 mb-1" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.bookingData.hotelName) +
                              "\n            "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.bookingData.numberOfAdults) +
                              " "
                          ),
                          _vm.bookingData.numberOfAdults == 1
                            ? _c("span", [_vm._v("Adulto")])
                            : _c("span", [_vm._v("Adulti")]),
                          _vm._v(",\n            "),
                          _vm.bookingData.childs.length > 0
                            ? _c("span", [
                                _vm._v(
                                  "\n                              " +
                                    _vm._s(_vm.bookingData.childs.length) +
                                    " "
                                ),
                                _vm.bookingData.childs.length == 1
                                  ? _c("span", [_vm._v("Bambino")])
                                  : _c("span", [_vm._v("Bambini")]),
                                _vm._v(",\n                          "),
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.bookingData.roomDescription) +
                              "\n            "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.bookingData.nights) +
                              " "
                          ),
                          _vm.bookingData.nights == 1
                            ? _c("span", [_vm._v("Notte")])
                            : _c("span", [_vm._v("Notti")]),
                          _vm.rateAmenities !== 0
                            ? _c("div", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.rateAmenities) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.bookingHidePrice &&
                      !_vm.mixinGetIntegrationInfo().isGiftCard
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("hr"),
                              _vm._m(1),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.bookingData.public_price) + " €"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.bookingHidePrice &&
                      !_vm.mixinGetIntegrationInfo().isGiftCard
                        ? _c("hr")
                        : _vm._e(),
                      _vm.mixinGetIntegrationInfo().mode == "booking" &&
                      _vm.fees !== 0
                        ? _c("div", { staticClass: "sidebarResumeBlock" }, [
                            _c("strong", [
                              _vm._v("Costi obbligatori non inclusi"),
                            ]),
                            _vm.bookingData.totals.mandatory_tax !== 0
                              ? _c("div", { staticClass: "sidebarRow" }, [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value:
                                            "Il costo della tassa di soggiorno viene definito dall'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell'Amministrazione Comunale o a contattare la struttura alberghiera.",
                                          expression:
                                            "'Il costo della tassa di soggiorno viene definito dall\\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\\'Amministrazione Comunale o a contattare la struttura alberghiera.'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n                Tassa di soggiorno\n                "
                                      ),
                                      _c("SvgInfoCircle"),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mixinRoundTwoDigits(
                                          _vm.bookingData.totals.mandatory_tax
                                        )
                                      ) + " €"
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.bookingData.totals.mandatory_fee !== 0
                              ? _c("div", { staticClass: "sidebarRow" }, [
                                  _c("span", [
                                    _vm._v("Spese di pulizia ed utenze"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mixinRoundTwoDigits(
                                          _vm.bookingData.totals.mandatory_fee
                                        )
                                      ) + " €"
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.bookingData.totals.resort_fee !== 0
                              ? _c("div", { staticClass: "sidebarRow" }, [
                                  _c("span", [_vm._v("Resort fee")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.mixinRoundTwoDigits(
                                          _vm.bookingData.totals.resort_fee
                                        )
                                      ) + " €"
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._m(2),
                          ])
                        : _vm.mixinGetIntegrationInfo().mode == "booking"
                        ? _c("div", { staticClass: "sidebarResumeBlock" }, [
                            _c("strong", [
                              _vm._v("Costi obbligatori non inclusi"),
                            ]),
                            _vm._v("\n             \n            "),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-center" },
                              [
                                _vm._v(
                                  "\n              La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.\n            "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("hr"),
                _c("h5", { staticClass: "sidebarAccordionTitle" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.checkoutStatusUserData",
                          modifiers: { checkoutStatusUserData: true },
                        },
                      ],
                    },
                    [
                      _c("span", { staticClass: "checkoutDynamicBlockTitle" }, [
                        _c("span", { staticClass: "stepNumber" }, [
                          _vm._v("1"),
                        ]),
                        _vm._v(" Dati personali "),
                        _c(
                          "div",
                          { staticClass: "sidebarAccordionIcon" },
                          [_c("SvgChevronDown", { staticClass: "chevron" })],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "b-collapse",
                  {
                    attrs: {
                      id: "checkoutStatusUserData",
                      accordion: "checkoutStatus",
                    },
                    model: {
                      value: _vm.checkoutStatusUserDataVisible,
                      callback: function ($$v) {
                        _vm.checkoutStatusUserDataVisible = $$v
                      },
                      expression: "checkoutStatusUserDataVisible",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "sidebarResumeBlock" },
                      [
                        _c("p", { staticClass: "text-center" }, [
                          _c("small", [
                            _vm._v(
                              "I campi con l'asterisco * sono obbligatori"
                            ),
                          ]),
                        ]),
                        _vm.sidebarBlocks.checkoutPax.initialize
                          ? _c("CheckoutPax", {
                              ref: _vm.sidebarBlocks.checkoutPax.ref,
                              attrs: {
                                inputSize:
                                  _vm.sidebarBlocks.checkoutPax.inputSize,
                              },
                              on: {
                                sidebarBlockChanged: _vm.sidebarBlockChange,
                              },
                            })
                          : _vm._e(),
                        _vm.sidebarBlocks.checkoutBillToPax.initialize
                          ? _c("CheckoutBillToPax", {
                              ref: _vm.sidebarBlocks.checkoutBillToPax.ref,
                              attrs: {
                                inputSize:
                                  _vm.sidebarBlocks.checkoutBillToPax.inputSize,
                              },
                              on: {
                                sidebarBlockChanged: _vm.sidebarBlockChange,
                              },
                            })
                          : _vm._e(),
                        _vm.sidebarBlocks.checkoutGiftCard.initialize
                          ? _c("CheckoutGiftCard", {
                              ref: _vm.sidebarBlocks.checkoutGiftCard.ref,
                              attrs: {
                                inputSize:
                                  _vm.sidebarBlocks.checkoutGiftCard.inputSize,
                                pax: _vm.sidebarBlocks.checkoutPax,
                                billToPax: _vm.sidebarBlocks.checkoutBillToPax,
                              },
                              on: {
                                sidebarBlockChanged: _vm.sidebarBlockChange,
                              },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "checkoutStatusButtons mt-4" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName:
                                      "v-b-toggle.checkoutStatusPaymentMethods",
                                    modifiers: {
                                      checkoutStatusPaymentMethods: true,
                                    },
                                  },
                                ],
                                attrs: {
                                  disabled: !_vm.getUserDataStatus(),
                                  variant: "success",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "fb-px-initiate-checkout",
                                      {}
                                    )
                                  },
                                },
                              },
                              [_vm._v("Conferma")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                false
                  ? _c(
                      "div",
                      { staticClass: "sidebarStep checkoutCodes" },
                      [
                        _vm.getUserDataStatus()
                          ? _c(
                              "h5",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName:
                                      "v-b-toggle.checkoutStatusPaymentMethods",
                                    modifiers: {
                                      checkoutStatusPaymentMethods: true,
                                    },
                                  },
                                ],
                                staticClass: "sidebarAccordionTitle",
                              },
                              [
                                _vm._m(3),
                                _c("SvgChevronDown", {
                                  staticClass: "chevron",
                                }),
                              ],
                              1
                            )
                          : _c("h5", { staticClass: "sidebarAccordionTitle" }, [
                              _vm._m(4),
                            ]),
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              id: "checkoutStatusPaymentMethods",
                              accordion: "checkoutStatus",
                            },
                            model: {
                              value: _vm.checkoutStatusPaymentMethodsVisible,
                              callback: function ($$v) {
                                _vm.checkoutStatusPaymentMethodsVisible = $$v
                              },
                              expression: "checkoutStatusPaymentMethodsVisible",
                            },
                          },
                          [
                            !_vm.loadingDc
                              ? _c(
                                  "div",
                                  { staticClass: "sidebarResumeBlock" },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { role: "tablist" } },
                                      [
                                        _c("b-form-group", [
                                          _vm.codes.length > 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.codes,
                                                  function (code, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: "dc_" + index,
                                                        class:
                                                          "sidebarRow discountCodes",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            class:
                                                              "discountCode " +
                                                              (code.usable
                                                                ? code.valueUsed >
                                                                  0
                                                                  ? "validCode"
                                                                  : "warningCode"
                                                                : "invalidCode") +
                                                              " " +
                                                              (code.usable &&
                                                              !code.divisible &&
                                                              code.valueUsed ==
                                                                0
                                                                ? "warningCode "
                                                                : "") +
                                                              (code.valueUsed ==
                                                              code.valueResidual
                                                                ? "fullDot "
                                                                : "") +
                                                              ((code.valueUsed >
                                                                0) &
                                                              (code.valueUsed <
                                                                code.valueResidual)
                                                                ? "halfDot "
                                                                : "") +
                                                              (code.valueUsed ==
                                                              0
                                                                ? "grayDot "
                                                                : "") +
                                                              (!code.usable
                                                                ? "redDot "
                                                                : ""),
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "b-toggle",
                                                                    rawName:
                                                                      "v-b-toggle",
                                                                    value:
                                                                      "accordion-discountCode-" +
                                                                      index,
                                                                    expression:
                                                                      "'accordion-discountCode-' + index",
                                                                  },
                                                                ],
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "discountCodeTitle",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "icon",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "SvgEtc"
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                  Buono sconto\n                                                                  "
                                                                            ),
                                                                            !code.divisible &&
                                                                            code.status ==
                                                                              "VALID"
                                                                              ? _c(
                                                                                  "span",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "b-popover",
                                                                                          rawName:
                                                                                            "v-b-popover.hover.top",
                                                                                          value:
                                                                                            "Questo Buono può essere speso solo in modo completo e per una singola prenotazione",
                                                                                          expression:
                                                                                            "'Questo Buono può essere speso solo in modo completo e per una singola prenotazione'",
                                                                                          modifiers:
                                                                                            {
                                                                                              hover: true,
                                                                                              top: true,
                                                                                            },
                                                                                        },
                                                                                      ],
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                      NON frazionabile "
                                                                                    ),
                                                                                    _c(
                                                                                      "SvgInfoCircle",
                                                                                      {
                                                                                        staticClass:
                                                                                          "svgInfo",
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "SvgChevronDown",
                                                                      {
                                                                        staticClass:
                                                                          "chevron",
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "discountCodeRow",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "copy copyDots",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "dcCode",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                code.discountCode
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    code.status !=
                                                                    "EXPIRED"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "spacer",
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    code.status !=
                                                                    "EXPIRED"
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "value",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.mixinRoundTwoDigits(
                                                                                      code.valueUsed
                                                                                    )
                                                                                  ) +
                                                                                    "€"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-collapse",
                                                              {
                                                                attrs: {
                                                                  id:
                                                                    "accordion-discountCode-" +
                                                                    index,
                                                                  accordion:
                                                                    "accordion-discountCodes",
                                                                },
                                                              },
                                                              [
                                                                code.valueUsed ==
                                                                0
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "discountCodeRow danger",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "mt-1 mb-1 copy d-flex justify-content-center align-items-center",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-1",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "font-awesome-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        icon: "exclamation-triangle",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            code.status ==
                                                                            "NOT FOUND"
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                  Non abbiamo trovato questo buono sconto. Assicurati di averlo digitato correttamente rispettando MAIUSCOLE  e minuscole\n                                                              "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : code.status ==
                                                                                "EXPIRED"
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                  Buono sconto scaduto il " +
                                                                                        _vm._s(
                                                                                          code.expirationDate
                                                                                        ) +
                                                                                        "\n                                                              "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : code.status ==
                                                                                "EMPTY"
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                  Questo buono sconto è già stato interamente utilizzato per un'altra prenotazione\n                                                              "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : code.status ==
                                                                                "DISABLED"
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                  Questo buono sconto non è attivo, contatta l'assistenza\n                                                              "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : !code.divisible &&
                                                                                code.valueUsed ==
                                                                                  0
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                  Questo buono è di tipo non frazionabile e non può essere speso parzialmente\n                                                              "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : code.status ==
                                                                                "NOT CUMULABLE"
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                  Questo buono sconto non è cumulabile, togli gli altri codici dalla lista per utilizzare questo\n                                                              "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                code.cumulable ==
                                                                false
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "discountCodeRow",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-popover",
                                                                                  rawName:
                                                                                    "v-b-popover.hover.top",
                                                                                  value:
                                                                                    "Questo buono non può essere utilizzato congiuntamente ad altri buoni",
                                                                                  expression:
                                                                                    "'Questo buono non può essere utilizzato congiuntamente ad altri buoni'",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      top: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "copy",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "SvgInfoCircle",
                                                                              {
                                                                                staticClass:
                                                                                  "svgInfo",
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  "Non cumulabile"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "discountCodeRow",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-popover",
                                                                              rawName:
                                                                                "v-b-popover.hover.top",
                                                                              value:
                                                                                "Valore del Buon all'emissione",
                                                                              expression:
                                                                                "'Valore del Buon all\\'emissione'",
                                                                              modifiers:
                                                                                {
                                                                                  hover: true,
                                                                                  top: true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "copy",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "SvgInfoCircle",
                                                                          {
                                                                            staticClass:
                                                                              "svgInfo",
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " Valore originario\n                            "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c("div", {
                                                                      staticClass:
                                                                        "spacer",
                                                                    }),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.mixinRoundTwoDigits(
                                                                              code.valueNominal
                                                                            )
                                                                          ) +
                                                                            "€"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                code.status ==
                                                                "VALID"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "discountCodeRow",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-popover",
                                                                                  rawName:
                                                                                    "v-b-popover.hover.top",
                                                                                  value:
                                                                                    "In base a possibili utilizzi del Buono sconto per precedenti prenotazioni",
                                                                                  expression:
                                                                                    "'In base a possibili utilizzi del Buono sconto per precedenti prenotazioni'",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      top: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "copy",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "SvgInfoCircle",
                                                                              {
                                                                                staticClass:
                                                                                  "svgInfo",
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " Valore disponibile\n                            "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "spacer",
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "value",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.mixinRoundTwoDigits(
                                                                                  code.valueResidual
                                                                                )
                                                                              ) +
                                                                                "€"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                code.status ==
                                                                "VALID"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "discountCodeRow",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-popover",
                                                                                  rawName:
                                                                                    "v-b-popover.hover.top",
                                                                                  value:
                                                                                    "Il valore del Buono sconto " +
                                                                                    (code.valueUsed ==
                                                                                    code.valueResidual
                                                                                      ? "viene utilizzato in toto per questa prenotazione"
                                                                                      : "") +
                                                                                    ((code.valueUsed >
                                                                                      0) &
                                                                                    (code.valueUsed <
                                                                                      code.valueResidual)
                                                                                      ? "viene utilizzato solo parzialmente per questa prenotazione "
                                                                                      : "") +
                                                                                    (code.valueUsed ==
                                                                                    0
                                                                                      ? "non verrà utilizzato per questa prenotazione. Resterà valido per altre prenotazioni"
                                                                                      : ""),
                                                                                  expression:
                                                                                    "\n                                                              'Il valore del Buono sconto '\n                                                              + (code.valueUsed == code.valueResidual ? 'viene utilizzato in toto per questa prenotazione' : '')\n                                                              + (code.valueUsed > 0 & (code.valueUsed < code.valueResidual) ? 'viene utilizzato solo parzialmente per questa prenotazione ' : '')\n                                                              + (code.valueUsed == 0 ? 'non verrà utilizzato per questa prenotazione. Resterà valido per altre prenotazioni' : '')\n                                                          ",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      top: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "copy copyDots",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "SvgInfoCircle",
                                                                              {
                                                                                staticClass:
                                                                                  "svgInfo",
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  "Utilizzo per questa prenotazione"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "spacer",
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "value",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.mixinRoundTwoDigits(
                                                                                      code.valueUsed
                                                                                    )
                                                                                  ) +
                                                                                    "€"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                code.status ==
                                                                "VALID"
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "discountCodeRow",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "b-popover",
                                                                                  rawName:
                                                                                    "v-b-popover.hover.top",
                                                                                  value:
                                                                                    "Ti invieremo una email con un riepilogo per un futuro utilizzo del residuo",
                                                                                  expression:
                                                                                    "'Ti invieremo una email con un riepilogo per un futuro utilizzo del residuo'",
                                                                                  modifiers:
                                                                                    {
                                                                                      hover: true,
                                                                                      top: true,
                                                                                    },
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "copy",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "SvgInfoCircle",
                                                                              {
                                                                                staticClass:
                                                                                  "svgInfo",
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " Valore disponibile dopo la prenotazione\n                            "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "spacer",
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "value",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.mixinRoundTwoDigits(
                                                                                  code.valueResidual -
                                                                                    code.valueUsed
                                                                                )
                                                                              ) +
                                                                                "€"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "discountCodeRemove",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeCode(
                                                                            code.discountCode
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    code.status ==
                                                                    "VALID"
                                                                      ? _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Non utilizzare"
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "Togli dalla lista"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                    _c(
                                                                      "span",
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "trash",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          !_vm.thereIsNonCumulableDiscountCode
                                            ? _c(
                                                "div",
                                                { staticClass: "sidebarRow" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "newDiscountCodeBox",
                                                    },
                                                    [
                                                      _c("b-input", {
                                                        ref: "newDiscountCode",
                                                        staticClass:
                                                          "newDiscountCode invalid",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            "es: XXXXXXXXXXXXXXXX",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.validateCode(
                                                              _vm.newDiscountCode,
                                                              "newDiscountCode"
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newDiscountCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.newDiscountCode =
                                                              $$v
                                                          },
                                                          expression:
                                                            "newDiscountCode",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: { size: "sm" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addCode()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("AGGIUNGI")]
                                                      ),
                                                      _c("p", [
                                                        _vm._v(
                                                          "aggiungi un buono"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "togglePayment black",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: "money-bill-alt",
                                                  },
                                                }),
                                                _vm._v(
                                                  " Riepilogo pagamenti\n                                      "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "sidebarRow discountCodes",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "dcResume" },
                                              [
                                                _vm.availabilityToUse > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "discountCodeRow",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "copy",
                                                          },
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: "money-bill-alt",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(
                                                              " Credito\n                      "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", {
                                                          staticClass: "spacer",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "value",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.mixinRoundTwoDigits(
                                                                  _vm.availabilityToUse
                                                                )
                                                              ) + " €"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.mixinRoundTwoDigits(
                                                  _vm.discountCodesAmount
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "discountCodeRow",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "copy",
                                                          },
                                                          [
                                                            _c("SvgEtc"),
                                                            _vm._v(
                                                              " Codici sconto\n                      "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", {
                                                          staticClass: "spacer",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "value",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.mixinRoundTwoDigits(
                                                                  _vm.discountCodesAmount
                                                                )
                                                              ) + " €"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.mixinRoundTwoDigits(
                                                  _vm.creditCardAmount
                                                ) > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "discountCodeRow",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "copy",
                                                          },
                                                          [
                                                            _c("SvgCreditCard"),
                                                            _vm._v(
                                                              " Carta di credito\n                      "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", {
                                                          staticClass: "spacer",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "value",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.mixinRoundTwoDigits(
                                                                  _vm.creditCardAmount
                                                                )
                                                              ) + " €"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "checkoutStatusButtons mt-4",
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName:
                                                  "v-b-toggle.checkoutStatusConfirm",
                                                modifiers: {
                                                  checkoutStatusConfirm: true,
                                                },
                                              },
                                            ],
                                            attrs: { variant: "success" },
                                          },
                                          [_vm._v("Procedi")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _c("Spinner"),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("hr"),
                _c("h5", { staticClass: "sidebarAccordionTitle" }, [
                  _vm.getUserDataStatus()
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName:
                                "v-b-toggle.checkoutStatusPaymentMethods",
                              modifiers: { checkoutStatusPaymentMethods: true },
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "checkoutDynamicBlockTitle" },
                            [
                              _c("span", { staticClass: "stepNumber" }, [
                                _vm._v("2"),
                              ]),
                              _vm._v(" Modalità di pagamento "),
                              _c(
                                "div",
                                { staticClass: "sidebarAccordionIcon" },
                                [
                                  _c("SvgChevronDown", {
                                    staticClass: "chevron",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c("div", [_vm._m(5)]),
                ]),
                _c(
                  "b-collapse",
                  {
                    attrs: {
                      id: "checkoutStatusPaymentMethods",
                      accordion: "checkoutStatus",
                    },
                    model: {
                      value: _vm.checkoutStatusPaymentMethodsVisible,
                      callback: function ($$v) {
                        _vm.checkoutStatusPaymentMethodsVisible = $$v
                      },
                      expression: "checkoutStatusPaymentMethodsVisible",
                    },
                  },
                  [
                    _vm.mixinGetIntegrationInfo().mode == "booking" &&
                    _vm.mixinGetIntegrationInfo().upselling.enabled
                      ? _c("div", { staticClass: "sidebarResumeBlock" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                                  Salabam " +
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm.mixinGetIntegrationInfo()
                                          .integration.salabam.focus
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm.mixinGetIntegrationInfo()
                                          .integration.salabam.band
                                      )
                                    ) +
                                    " "
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.mixinGetIntegrationInfo().integration
                                        .salabam.nights
                                    ) + " "
                                  ),
                                  _vm.mixinGetIntegrationInfo().integration
                                    .salabam.nights > 1
                                    ? _c("span", [_vm._v("notti")])
                                    : _c("span", [_vm._v("notte")]),
                                ]),
                              ]),
                              !_vm.bookingHidePrice &&
                              !_vm.mixinGetIntegrationInfo().isGiftCard
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                  " +
                                        _vm._s(
                                          (_vm.mixinGetIntegrationInfo()
                                            .integration.salabam.public_price *
                                            100) /
                                            100
                                        ) +
                                        " €\n                              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.mixinGetIntegrationInfo().mode == "booking" &&
                    !_vm.mixinGetIntegrationInfo().upselling.enabled
                      ? _c("div", { staticClass: "sidebarResumeBlock" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                                  Salabam " +
                                    _vm._s(
                                      _vm._f("capitalize")(
                                        _vm.bookingData.focus
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("capitalize")(_vm.bookingData.band)
                                    ) +
                                    " "
                                ),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.dates.nights) + " "),
                                  _vm.dates.nights > 1
                                    ? _c("span", [_vm._v("notti")])
                                    : _c("span", [_vm._v("notte")]),
                                ]),
                              ]),
                              !_vm.bookingHidePrice &&
                              !_vm.mixinGetIntegrationInfo().isGiftCard
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                  " +
                                        _vm._s(_vm.bookingData.public_price) +
                                        " €\n                              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.creditCardAmount > 0
                      ? _c(
                          "div",
                          { staticClass: "sidebarResumeBlock" },
                          [
                            _c("Stripe", {
                              ref: "stripe",
                              attrs: { amount: _vm.creditCardAmount },
                              on: {
                                "stripe-change": _vm.stripeChange,
                                "stripe-confirm": _vm.stripeConfirm,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "sidebarResumeBlock" }, [
                      _c(
                        "div",
                        { staticClass: "checkoutStatusButtons mt-4" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.checkoutStatusConfirm",
                                  modifiers: { checkoutStatusConfirm: true },
                                },
                              ],
                              attrs: {
                                disabled: !_vm.getPaymentMethodsStatus(),
                                variant: "success",
                              },
                            },
                            [_vm._v("Conferma")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("hr"),
                _c("h5", { staticClass: "sidebarAccordionTitle" }, [
                  _vm.getPaymentMethodsStatus()
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.checkoutStatusConfirm",
                              modifiers: { checkoutStatusConfirm: true },
                            },
                          ],
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "checkoutDynamicBlockTitle" },
                            [
                              _c("span", { staticClass: "stepNumber" }, [
                                _vm._v("2"),
                              ]),
                              _vm._v(" Conferma "),
                              _c(
                                "div",
                                { staticClass: "sidebarAccordionIcon" },
                                [
                                  _c("SvgChevronDown", {
                                    staticClass: "chevron",
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c("div", [_vm._m(6)]),
                ]),
                _c(
                  "b-collapse",
                  {
                    attrs: {
                      id: "checkoutStatusConfirm",
                      accordion: "checkoutStatus",
                    },
                    model: {
                      value: _vm.checkoutStatusConfirmVisible,
                      callback: function ($$v) {
                        _vm.checkoutStatusConfirmVisible = $$v
                      },
                      expression: "checkoutStatusConfirmVisible",
                    },
                  },
                  [
                    _c("div", { staticClass: "sidebarResumeBlock" }, [
                      _c("div", { staticClass: "sidebarRow mb-2" }, [
                        !_vm.sidebarBlocks.checkoutGiftCard.status
                          ? _c("small", [
                              _vm._v(
                                "\n                Il voucher Salabam verrà inviato all'indirizzo: "
                              ),
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.sidebarBlocks.checkoutPax.fields
                                      .guestEmail
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "sidebarRow mb-0" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-0",
                              attrs: { id: "checkout-form" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "privacy-box" },
                                [
                                  _c("b-form-checkbox", {
                                    ref: "checkoutPrivacy",
                                    attrs: {
                                      required: "",
                                      type: "checkbox",
                                      name: "checkbox",
                                      id: "checkbox-privacy",
                                    },
                                    model: {
                                      value: _vm.guestPrivacy,
                                      callback: function ($$v) {
                                        _vm.guestPrivacy = $$v
                                      },
                                      expression: "guestPrivacy",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "checkbox-privacy" } },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nounderline",
                                          attrs: {
                                            to: { name: "terms" },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "secondary" },
                                            [
                                              _vm._v(
                                                "Ho preso visione delle condizioni d'uso e le accetto"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "privacy-box" },
                                [
                                  _c("b-form-checkbox", {
                                    ref: "checkoutGdpr",
                                    attrs: {
                                      required: "",
                                      type: "checkbox",
                                      name: "checkbox",
                                      id: "checkbox-gdpr",
                                    },
                                    model: {
                                      value: _vm.guestGdpr,
                                      callback: function ($$v) {
                                        _vm.guestGdpr = $$v
                                      },
                                      expression: "guestGdpr",
                                    },
                                  }),
                                  _c(
                                    "label",
                                    { attrs: { for: "checkbox-gdpr" } },
                                    [
                                      _c("p", { staticClass: "mb-0" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.site.urlPrivacy,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "secondary" },
                                              [
                                                _vm._v(
                                                  "Ho preso visione della privacy policy e la accetto"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.disclaimerCheckout !== undefined
                                ? _c("div", { staticClass: "privacy-box" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "checkbox-gdpr" } },
                                      [
                                        _c("p", {
                                          staticClass: "checkboxInfo",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.disclaimerCheckout
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                              !_vm.loading
                                ? _c(
                                    "div",
                                    [
                                      _vm.creditCardAmount <= 0
                                        ? _c(
                                            "b-button",
                                            {
                                              staticClass: "btn btn-block",
                                              attrs: {
                                                variant: "success",
                                                disabled:
                                                  !_vm.canFinallyCheckout(),
                                                "data-e2e":
                                                  "confirmPurchaseBoxsetButton",
                                              },
                                              on: {
                                                click: _vm.checkoutConfirm,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Conferma\n                  "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "b-button",
                                            {
                                              staticClass: "btn btn-block",
                                              attrs: {
                                                variant: "success",
                                                disabled:
                                                  !_vm.canFinallyCheckout(),
                                                "data-e2e":
                                                  "confirmPurchaseBoxsetButton",
                                              },
                                              on: {
                                                click:
                                                  _vm.getIntentClientSecret,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Conferma\n                  "
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  )
                                : _c("Spinner", {
                                    attrs: { msg: _vm.loadingText },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "sidebarStepTitle" }, [
      _c(
        "span",
        { staticClass: "checkoutDynamicBlockTitle justify-content-center" },
        [_vm._v("Il tuo carrello")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Totale per il viaggio")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("small", [_vm._v("Da pagare in struttura al check-in")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkoutDynamicBlockTitle" }, [
      _c("span", { staticClass: "stepNumber" }, [_vm._v("2")]),
      _vm._v(
        "\n                              Metodi di pagamento\n                          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkoutDynamicBlockTitle" }, [
      _c("span", { staticClass: "stepNumber" }, [_vm._v("2")]),
      _vm._v(
        "\n                              Metodi di pagamento\n                          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkoutDynamicBlockTitle" }, [
      _c("span", { staticClass: "stepNumber" }, [_vm._v("2")]),
      _vm._v(" Modalità di pagamento\n                          "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkoutDynamicBlockTitle" }, [
      _c("span", { staticClass: "stepNumber" }, [_vm._v("2")]),
      _vm._v(" Conferma\n                          "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }